.Governorates{
    .mission-type-txt{  
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .other-reason-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding-right: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
       }
    .other-reason-input:focus{
        outline: none;
     }
     .other-reason-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .mission-address-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding-right: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
       }
       .mission-address-input:focus{
        outline: none;
        }
        .mission-address-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        }
    .select-mission-type{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* Heading/H3 */
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        letter-spacing: 0.2px;
    }
    .input-search{
        padding-right: 45px;
    }
    .input-search::placeholder{
        color: var(--Greyscale-500, #64748B);
        text-align: right;       
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
    .scrollable-list{
        // overflow-y: scroll;
        height: 10%;
    }
    .list-text{
        color: #64748B;
    text-align: right;

    /* Headings/H5 - 16 Bold - Lh 130% */
    font-family: MadaniArabic-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    }
    .done{
        display: flex;
        width: 142px;
        padding: 8px 0px 9px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        cursor: pointer;

    }
    .cancel{
        display: flex;
        width: 135px;
        height: 37px;
        padding: 8px 44px 9px 41px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #EEE;
        background: #FFF;
        display: flex;
        // padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
    .are-you-sure{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
    }
    .react-datepicker-wrapper {
        width: 100%;
      }
    
      .react-datepicker__input-container {
        width: 100%;
    
        input {
          width: 100%;
          display: flex;       
          height: 28px;
          padding: 17px 23px 17px 26px;
          justify-content: center;
          align-items: flex-start;        
          flex-shrink: 0;
          border-radius: 10px;
          border: 1px solid #E2E2E2;
          background: #FFF;
        //   background-image: url('../../../../Assets/Icons/Calender.jpeg');


          background-size: 8%;  
          background-repeat: no-repeat;
          background-position: 20px center; 
         //  padding-left: 5px;
        }
      }
    
      .react-datepicker {
        font-size: 16px;
        background-color: #f0f0f0;
        border-radius: 8px;
        overflow: hidden;
    
        .react-datepicker__header {
          background-color: #333;
          color: #fff;
        }
    
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          color: #333;
        }
    
        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
          background-color: #007bff;
          color: #fff;
        }
      }
    // Media query for small screens
@media (max-width: 600px) {
   
      .react-datepicker__input-container {
         width: 100%;
     
         input {
           width: 110%;
           display: flex;       
           height: 48px;
           font-size: 13px;
           padding: 17px 10px 17px 0px;
           justify-content: center;
           align-items: flex-start;        
           flex-shrink: 0;
           border-radius: 15px;
           border: 1px solid #E2E2E2;
           background: #FFF;
        //    background-image: url('../../../../Assets/Icons/Calender.jpeg');
           background-size: 14%;  
           background-repeat: no-repeat;
           background-position: 20px center; 
           padding-left: 5px;
         }
       }
   
 }
}
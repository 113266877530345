.logo{
    height: 80px;
    width: 100%;
}
.pdf-icon{
    width: 27px;
    height: 32px;
    cursor: pointer;
}
.farm-view{
  height: 180px;
  width: 100%;

}
.Head-label{
    color: var(--Black-01, #2C3659);
    text-align: right;                      
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
}
.label-content{
    color: var(--Black-01, #2C3659);
    text-align: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;  
}

.pass-content {
    display: none; /* Default visibility hidden on screen */
    direction: rtl;
  }

  @media print {
    .pass-content {
      display: block; /* Show the div only when printing */
    direction: rtl;

    }
  }
.MultiSelectDropdown{
    .prompt{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .list-text{
        color: #64748B;
        text-align: right;
    
        /* Headings/H5 - 16 Bold - Lh 130% */
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
     }
     .selected_txt{
        color: #124734;
        font-size: 16px;
        font-family: MadaniArabic-Regular;


     }
}
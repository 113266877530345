.EditFarm{
    .farm-info-section{
 
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
       }
       .wrap{
        text-wrap: nowrap;
    }
    .no-wrap{
        text-wrap: balance;
    }
       .license-section{
     
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
       }
       .location-section{
     
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
       }
       .attachment-section{
     
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
       }
       .add-actions{
        
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
       }
       .farm-info-title{
            display: flex;
            height: 40px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #F9F9F9;
            color: var(--Greyscale-500, #64748B);
            text-align: right;
            font-family: MadaniArabic-Regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 19.2px */
       }
       .title{
        color: #333;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
       }
       .input-err{
        display: flex;
        width: 100%;
        height: 55px;
        padding-right: 10px;        
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid red;
        background: #FFF;
        text-align: right;
        font-size: 14px;
    }
    .input-err:focus{
        outline: none;
    }
       .mission-address-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding-right: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
       }
       .mission-address-input:focus{
        outline: none;
        }
        .mission-address-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        }
       .select-mission-type{
        color: #333;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-color: white;
        }
        .select-mission-container{
        display: flex;
        height: 55px;
        padding: 17px 23px 17px 19.5px;
        justify-content: space-between;
        align-items: center;
        gap: 2px;
        
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
        width: 100%;
        }
        .file-upload {
        background-color: #ffffff;
        width: 600px;
        margin: 0 auto;
        padding: 20px;
        }
      
      .file-upload-btn {
        width: 100%;
        margin: 0;
        color: #fff;
        background: #1FB264;
        border: none;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 4px solid #15824B;
        transition: all .2s ease;
        outline: none;
        text-transform: uppercase;
        font-weight: 700;
      }
      
      .file-upload-btn:hover {
        background: #1AA059;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
      }
      
      .file-upload-btn:active {
        border: 0;
        transition: all .2s ease;
      }
      
      .file-upload-content {
        /* display: none; */
        text-align: center;
      }
      
      .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
        
        background: rgba(112, 212, 75, 0.05);
        backdrop-filter: blur(1px);
      }
      
      .image-upload-wrap {    
      border-radius: 10px;
      border: 1px dashed #70D44B;
      position: relative;
      height: 80px;
      width: 80%;
      background: rgba(112, 212, 75, 0.05);
      backdrop-filter: blur(1px);
      }
      
      .image-dropping,
      .image-upload-wrap:hover {
        
      }
      
      .image-title-wrap {
        padding: 0 15px 15px 15px;
        color: #222;
      }
      
      .drag-text {
        margin-top: 15px;
        text-align: center;
      }
      
      .drag-text h3 {
        color: var(--Black-01, #2C3659);
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
      }
      .drag-text p{
        color: var(--Grey-01, #A7AEC1);
        text-align: center; 
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
      }
      
      .file-upload-image {
        max-height: 200px;
        max-width: 200px;
        margin: auto;
        padding: 20px;
      }
      
      .remove-image {
        width: 200px;
        margin: 0;
        color: #fff;
        background: #cd4535;
        border: none;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 4px solid #b02818;
        transition: all .2s ease;
        outline: none;
        text-transform: uppercase;
        font-weight: 700;
      }
      
      .remove-image:hover {
        background: #c13b2a;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
      }
      
      .remove-image:active {
        border: 0;
        transition: all .2s ease;
      }
      .add-btn{
        display: inline-flex;
        padding: 8px 37.5px 9px 37.5px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;  
        cursor: pointer;
      }
      .cancel-btn{
          display: flex;
          width: 135px;
          height: 53px;
          padding: 8px 44px 9px 41px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 15px;
          border: 1px solid #EEE;
          background: #FFF;
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          cursor: pointer;
    
      }
      .add-photo-txt{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
      }
      .bonus-text{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.date-picker-container-component {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 10px;
 
   .react-datepicker-wrapper {
     width: 100%;
   }
 
   .react-datepicker__input-container {
     width: 100%;
 
     input {
       width: 100%;
       display: flex;       
       height: 48px;
       padding: 17px 23px 17px 26px;
       justify-content: center;
       align-items: flex-start;        
       flex-shrink: 0;
       border-radius: 15px;
       border: 1px solid #E2E2E2;
       background: #FFF;
       background-image: url('../../../../../Assets//Icons//Calender.jpeg');
       background-size: 4%;  
       background-repeat: no-repeat;
       background-position: 20px center; 
      //  padding-left: 5px;
     }
   }
 
   .react-datepicker {
     font-size: 16px;
     background-color: #f0f0f0;
     border-radius: 8px;
     overflow: hidden;
 
     .react-datepicker__header {
       background-color: #333;
       color: #fff;
     }
 
     .react-datepicker__day-name,
     .react-datepicker__day,
     .react-datepicker__time-name {
       color: #333;
     }
 
     .react-datepicker__day--selected,
     .react-datepicker__day--keyboard-selected {
       background-color: #007bff;
       color: #fff;
     }
   }
 }

// Media query for small screens
@media (max-width: 600px) {
   .date-picker-container-component {
      .react-datepicker__input-container {
         width: 100%;
     
         input {
           width: 110%;
           display: flex;       
           height: 48px;
           font-size: 13px;
           padding: 17px 10px 17px 0px;
           justify-content: center;
           align-items: flex-start;        
           flex-shrink: 0;
           border-radius: 15px;
           border: 1px solid #E2E2E2;
           background: #FFF;
          //  background-image: url('../../../../../Assets//Icons//Calender.jpeg');
          //  background-size: 14%;  
          //  background-repeat: no-repeat;
          //  background-position: 20px center; 
          //  padding-left: 5px;
         }
       }
   }
 }
.TotalCount{
    .totalCount{
        color: var(--Black-01, #2C3659);
        text-align: right;  
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .wrap{
        text-wrap: nowrap;
    }
}
.Competions{
    .title{
        color: var(--Black-01, #2C3659);
        text-align: right;  
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
        margin-bottom: 20px;
    }
    .competion-container{
        padding: 20px;   
        height: auto;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
        margin-bottom: 20px;
    }
    .competition-type{
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
    }
    .competion-address{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
        margin-bottom: 10px;
    }
    .finished-at{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .reward{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .status{
        display: flex;           
        padding: 8.757px 13.135px;
        justify-content: center;
        align-items: center;
        gap: 10.946px;
        flex-shrink: 0;
        border-radius: 54.73px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 10.946px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        height: 40px;

    }
    .rank{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .rank-input{
        width: 498px;
        height: 55px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #D8D013;
        background: #FFF;
    }
    .rank-input[type="text"] {
        color: var(--Greyscale-900, #0F172A);
        text-align: right;
        padding-right: 10px;
         
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.2px;
      }
    .rank-input:focus{
        outline: none;
    }
    .percentage{
        display: inline-flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .quesion-title{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .selected-answer{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #F1FAEE;
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        cursor: pointer;

    }
    .answer{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
        cursor: pointer;
    }
}
.Course_Details{
   .content{ 
      padding: 20px 20px 20px 20px;
      height: auto;
      flex-shrink: 0;
      // padding-bottom: 20px;
      border-radius: 10px;
      background: var(--White-General, #FFF);
      box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
     }
     .breedingCycle{
      padding: 20px 20px 20px 20px;
      height: auto;
      flex-shrink: 0;
      
      border-radius: 10px;
      background: var(--White-General, #FFF);
      box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
     }
     .farm-detials{
      color: var(--Black-01, #2C3659);
      text-align: right;  
      font-family: MadaniArabic-SemiBold;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;  
    }
    .farm-img{
        display: flex;
        width: 650px;
        height: 150px;
        border-radius: 10px;
    }
    .lecture-img{
        display: flex;
        width: 350px;
        height: 150px;
        border-radius: 10px;
    }
    .farm-name{
      color: #000;
      text-align: right;
      font-family: MadaniArabic-SemiBold;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 32px */
    }
    .title{
      color: #000;
      text-align: right;
      font-family: MadaniArabic-SemiBold;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
    }
    .title-content{
      color: #64748B; 
      font-family: MadaniArabic-Medium;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    .status{
      display: flex;
      width: 81px;
      height: 28.459px;
      padding: 8.757px 13.135px;
      justify-content: center;
      align-items: center;
      gap: 10.946px;
      flex-shrink: 0;
      border-radius: 54.73px;
      background: #FFCED3;
      text-align: center;
      font-family: MadaniArabic-Regular;
      font-size: 10.946px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;  
      color: #AF1C1C;
    }
    .range-farm{
        display: inline-flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Lgrey-02, #F9F9F9);
    }
    .map-container{
        border-radius: 20px;
        padding: 10px;
    }
    .map{
        border-radius: 20px;
        width: 100%;

    }
    .cycle_title{
      color: var(--Black-01, #2C3659);
      text-align: right;

      /* Headings/H5 - 16 Bold - Lh 130% */
      font-family: MadaniArabic-SemiBold;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
     }
    .cycle-content{
      color: var(--Greyscale-500, #64748B);
      text-align: right;
          
      /* body/small/regular */
      font-family: MadaniArabic-Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 19.2px */
  }
  .add-farm-btn{
      border-radius: 8px;
      background: #124734;
      display: flex;
      width: 160px;
      height: 40px;
      padding: 8px 12px 8px 17px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      color: #FFF;
      text-align: right; 
      font-family: MadaniArabic-Medium;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;  
      letter-spacing: 0.2px;
      cursor: pointer;
  }
  .add-user-btn{
   border-radius: 8px;
   background: #124734;
   display: flex;
   width: 164px;
   height: 40px;
   padding: 8px 19px 8px 31px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   color: #FFF;
   text-align: right;
   font-family: MadaniArabic-Medium;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 160%;
   letter-spacing: 0px;
   cursor: pointer;
  }
}
.CommingSoon{
    width: 1128px;
    height: 552px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--White-General, #FFF);
    box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    .text{
        color: var(--Neutral-Dark-Grey, #9C9CA4);        
        font-family: MadaniArabic-Medium;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
    }
}
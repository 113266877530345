.AddCompetitions{
    background: var(--Greyscale-50, #F8FAFC);
    .accordion-content {
        display: none;
        transition: opacity 0.8s ease-in-out;
      }
    
      .accordion-toggle:checked + label + .divider + .accordion-content {
        display: block;
        opacity: 1;
      }
    .add-section{ 
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .add-reward-section{
        height: 510px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .mission-details{
        width: 50%;
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    
    .mission-type{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .select-mission-type{
        color: #333;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-color: white;
    }
    .select-mission-container{
        display: flex;
        height: 55px;
        padding: 17px 23px 17px 19.5px;
        justify-content: space-between;
        align-items: flex-start;

        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        // background: #FFF;
       width: 100%;
    }
    .mission-map-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding: 17px 23px 17px 385px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0; 
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .mission-map-input:focus{
        outline: none;
    }
    .mission-map-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .mission-address-input{
        display: flex;
        width: 490px;
        height: 55px;
        padding-right: 10px;
        /* padding: 17px 23px 17px 385px; */
        /* justify-content: flex-end; */
        /* align-items: center; */
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .mission-address-input:focus{
        outline: none;
    }
    .mission-address-input::placeholder{
        display: flex;
         
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  
    .mission-text-area{
        display: flex;            
        padding: 22px 23px 60px 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .mission-text-area:focus{
        outline: none;
    }
    .bonus-text{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .add-actions{
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
         
    }
    .add-btn{
        display: inline-flex;
        padding: 8px 37.5px 9px 37.5px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;  
        cursor: pointer;
    }
    .cancel-btn{
        display: flex;
        width: 135px;
        height: 53px;
        padding: 8px 44px 9px 41px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #EEE;
        background: #FFF;
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;

    }
    .question-head{
        display: flex;
        width: 35%;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .add-question{
        width: 149px;
        border-radius: 15px;
        background: #124734;  
        height: 56px;
        flex-shrink: 0;
        color: #FFF;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }
    .questions-section{
        margin-left: 40px;
        margin-right: 40px;
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid #F1F1F1;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .questions-section{
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
        height: auto;
        padding: 10px;
        margin-top: 10px;
    }
    .question-number{
        color: #124734;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;  
    }
    .choise-section{
        border-top: 1px solid #E2E8F0;
        border-bottom: 1px solid #E2E8F0;
        display: flex;
        padding: 11px 23px;        
        align-items: center;
        gap: -28px;
    }
    .choise-txt{
        color: #124734;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
    }
    .input-choise{
        display: flex;
        width: 600px;
        height: 55px;
        padding-right: 10px;
        
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .input-choise:focus{
        outline: none;
    }
    .success_criteria{
         
        height: 245px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .criteria_title{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
}
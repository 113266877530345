.Academy_Courses{
    background: #F1F5F9;  
    padding-bottom: 20px;
    .title{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .courses-section{       
        padding: 30px;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
        border-radius: 16px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.05);
    }
    .status{        
        display: inline-flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Neutral-White, #FFF);
        backdrop-filter: blur(15px);
        color: #2C3659;
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
    }
    .course-img{
        border-radius: 20px;
    }
    .course-title{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        align-self: stretch;
    }
    .user-name{
        color: var(--Neutral-Dark-Grey, #9C9CA4);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;  
    }
    .number{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;  
    }
    .three_dots{
        display: inline-flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Neutral-White, #FFF);
        backdrop-filter: blur(15px);
    }
    .arrow{
        outline: none;
    }
    .popover-content{
        height:auto;
        padding-bottom: 80px;
    }
    .popover-right-top{
        background: #FFF;
        --tw-translate-y: 1.25rem;
        --tw-translate-x: -0.5rem;
        bottom: 0;
        top: 5px;
        left: 100%;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform-origin: bottom left;
        visibility: hidden;
    }
    .three-dots-li{
        display: flex;
        justify-content: start;
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .three-dots-li-delete{
        display: flex;
        justify-content: start;
        color: #EB4335;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        margin-bottom: 15px;
    }
    .score{
        color: #000;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .percentage{
        color: #124734;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .info-txt{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
}
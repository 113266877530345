.AllSuppliers{
    background: #F1F5F9;  
    padding-bottom: 20px;
    .big-section{ 
        padding: 30px;         
        border-radius: 16px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.05);
       
    }
    .supplier-important{
        font-family: MadaniArabic-Regular;
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
        color :#59cc2f
    }
    .status{        
        display: inline-flex;
       
        justify-content: center;
        align-items: center;
        
        border-radius: 8px;
        background: var(--Neutral-White, #FFF);
         
        color: #AF1C1C;
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        
    }
    .status-revision{
        display: inline-flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Neutral-White, #FFF);        
        color: #9C9CA4;
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
    }
    .course-img{
        border-radius: 20px;
        height: 220px;
        width: 100%;
    }
    .farm-img{
        border-radius: 20px;
        height: 220px;
        width: 100%;
    }
    .three_dots{
        display: inline-flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Neutral-White, #FFF);
        backdrop-filter: blur(15px);
    }
    .farm-name{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        text-wrap: balance;
    }
    .title{
        color: #000;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .title-content{
        color: #64748B; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    .sorting{
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    
    .sorting-item{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .farm-location{
        color: var(--Neutral-Dark-Grey, #9C9CA4);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .chicken-type{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .btn-pagination-active{
        background-color: #70D44B;
        color: #eeeeee;
    }
    .btn-pagination-active:hover{
     background-color: #a4d791;
     color: #eeeeee;
    }
    .pagination{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .arrow{
        outline: none;
    }
    .popover-content{
        height: 160px;
    }
    .popover-right-top{
        background: #FFF;
        --tw-translate-y: 1.25rem;
        --tw-translate-x: -0.5rem;
        bottom: 0;
        top: 5px;
        left: 100%;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform-origin: bottom left;
        visibility: hidden;
    }
    .three-dots-li{
        display: flex;
        justify-content: start;
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .three-dots-li-delete{
        display: flex;
        justify-content: start;
        color: #EB4335;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        margin-bottom: 15px;
    }
    .main-info-notes{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #64748B;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }

    /***** List Display Type ***** */
    
    .DisplayListType{
        .data-row{
            padding: 20px;
            background-color: #FFF;
            text-align: center;
       } 
        .bannerText{
           color: #64748B;
           font-family: MadaniArabic-Regular;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: normal;
           text-align: center;
      }
      .report{
       width: 182px;
       height: 103px;
       flex-shrink: 0;
       border-radius: 9.794px;
       border: 1px solid #E2E8F0;
       background: #FFF;
      }
      .amount{
       color: var(--Greyscale-900, #0F172A);
       text-align: right;
    
       /* Heading/H3 */
       font-family: MadaniArabic-SemiBold;
       font-size: 24px;
       font-style: normal;
       font-weight: 400;
       line-height: 125%; /* 30px */
       letter-spacing: 0.2px;
    }
    .coin{
       color: #70D44B;
       text-align: right;
    
       /* body/small/medium */
       font-family: MadaniArabic-Medium;
       font-size: 12px;
       font-style: normal;
       font-weight: 400;
       line-height: 160%; /* 19.2px */
    }
    .report-title{
       color: var(--Greyscale-500, #64748B);
       text-align: right;
                       
       /* body/small/regular */
       font-family: MadaniArabic-Regular;
       font-size: 12px;
       font-style: normal;
       font-weight: 400;
       line-height: 160%; /* 19.2px */
    }
    
    
       .breeder-name{
           color: var(--Greyscale-900, #0F172A);
           text-align: center;
           font-family: MadaniArabic-SemiBold;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; 
           letter-spacing: 0.2px;
       }
       .breeder-email{
           color: var(--Greyscale-900, #0F172A);
           text-align: center; 
           font-family: MadaniArabic-SemiBold;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%;  
           letter-spacing: 0.2px;
       }
       .breeder-phone{
           color: #28303F;
           text-align: center;
           font-family: MadaniArabic-Medium;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 22.4px */
       }
       .breeder-active{
           color: #70D44B;
           text-align: center;
           font-family: MadaniArabic-Medium;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 22.4px */
       }
       .breeder-disable{
           color: #EB001B;
           text-align: center;
           font-family: MadaniArabic-Medium;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 22.4px */
       }
       .breeder-eval{
           color: #28303F;
           text-align: center;
           font-family: MadaniArabic-Medium;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%;  
       }
       .breeder-city{
           color: #28303F;
           text-align: center;
           font-family: MadaniArabic-Medium;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; 
       }
       .btn-pagination-active{
           background-color: #70D44B;
           color: #eeeeee;
       }
          .btn-pagination-active:hover{
           background-color: #a4d791;
           color: #eeeeee;
       }
       .pagination{
           padding-bottom: 10px;
       }
       ::-webkit-scrollbar {
           width: 8px; /* Width of the entire scrollbar */
         }
         ::-webkit-scrollbar-thumb {
           background-color: #70D44B; /* Color of the scrollbar handle */
           border-radius: 25px; /* Roundness of the scrollbar handle */
         }
         .mission-type-txt{
           display: flex;
           height: 40px;
           padding: 8px 12px;
           justify-content: center;
           align-items: center;
           gap: 10px;
           border-radius: 8px;
           background: #F9F9F9;
           color: var(--Greyscale-500, #64748B);
           text-align: right;
           font-family: MadaniArabic-Regular;
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 19.2px */
       }
       .select-mission-type{
           color: var(--Greyscale-900, #0F172A);
           text-align: right;
    
           /* Heading/H3 */
           font-family: MadaniArabic-SemiBold;
           font-size: 16px;
           font-style: normal;
           font-weight: 400;
           line-height: 125%; /* 30px */
           letter-spacing: 0.2px;
       }
       .input-search{
           padding-right: 45px;
       }
       .input-search::placeholder{
           color: var(--Greyscale-500, #64748B);
           text-align: right;       
           font-family: MadaniArabic-Regular;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 22.4px */
       }
       .scrollable-list{
           // overflow-y: scroll;
           height: 10%;
       }
       .list-text{
           color: #64748B;
       text-align: right;
    
       /* Headings/H5 - 16 Bold - Lh 130% */
       font-family: MadaniArabic-SemiBold;
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: 130%; /* 20.8px */
       }
       .done{
           display: flex;
           width: 142px;
           padding: 8px 0px 9px 0px;
           justify-content: center;
           align-items: center;
           border-radius: 15px;
           background: #124734;
           color: #FFF;
           font-family: MadaniArabic-Medium;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: 16px; /* 114.286% */
           cursor: pointer;
       }
       .cancel{
           width: 135px;        
           padding: 2px 29px 9px 26px;
           flex-shrink: 0;
           border-radius: 15px;
           border: 1px solid #EEE;
           background: #FFF;
           display: flex;
           
           justify-content: center;
           align-items: center;
           gap: 10px;
           cursor: pointer;
       }
       .assign-type-active{
           display: inline-flex;
           height: 40px;
           padding: 8px 12px;
           justify-content: center;
           align-items: center;
           gap: 8px;
           flex-shrink: 0;
           color: #70D44B;
           text-align: right;
           border-radius: 8px;
           background: #F1FAEE;
           /* body/medium/bold */
           font-family: MadaniArabic-SemiBold;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 22.4px */
           cursor: pointer;
    
       }
       .assign-type{
           display: inline-flex;
           height: 40px;
           padding: 8px 12px;
           justify-content: center;
           align-items: center;
           gap: 8px;
           flex-shrink: 0;
           border-radius: 8px;
           background: #F9F9F9;
           color: var(--Greyscale-500, #64748B);
           text-align: right;
    
           /* body/medium/medium */
           font-family: MadaniArabic-Medium;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%; /* 22.4px */
           letter-spacing: 0.2px;
           cursor: pointer;
       }
       .number{
           color: #70D44B;
           font-family: MadaniArabic-Medium;
           font-size: 14px;
           font-style: normal;
           font-weight: 400;
           line-height: 160%;
       }
       .supplier-img{
            border-radius: 20px;
            height: 65px;
            width: 80%;
       }
    
    }
}

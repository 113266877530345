.Academy_Trainers{
    background: #F1F5F9;  
    padding-bottom: 20px;
    .data-row{
        padding: 20px;
        background-color: #FFF;
        text-align: center;
   } 
    .head_title{
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .content{
        color: #28303F;
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
    .trainer_img{
        display: flex;
        width: 100px;
        height: 60px;
        padding: 10px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
    }
}
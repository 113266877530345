.AllSuppliersDetails{
    .send-now{
        display: inline-flex;
        padding: 6px 19.5px 9px 22.5px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        cursor: pointer;
    }
    .breeder-header{
        
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .user-name{
        color: var(--Greyscale-900, #0F172A);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .evalu{
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
    }
    .member-since{
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
    }
    .breeder-item{
        color: var(--Neutral-Dark-Grey, #9C9CA4);
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        padding: 20px;
        cursor: pointer;
    }
    .breeder-item-active{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        padding: 20px;
        border-bottom: 2px solid #70D44B;
        cursor: pointer;
    }
    .account-data-section{
     
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .main-info{
        color: var(--Black-01, #2C3659);
        text-align: right;  
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .title{
        color: var(--Greyscale-900, #0F172A);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .data{
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
    }
    .title-content{
        color: #000;
        text-align: right;
        
        font-family: MadaniArabic-Medium;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        width: 100%;
        height: 55px;
        padding: 17px 15px 17px 327px;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .title-content:focus{
        outline: none;
    }
    .title-content::placeholder{
        color: var(--Greyscale-500, #A0AEC0);
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 12.87px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        letter-spacing: 0.276px;
        
    }
      .notes_title{
        color: #333;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .map-container{
        border-radius: 20px;
        padding: 10px;
    }
      .map{
        border-radius: 20px;
        width: 100%;

    }
    .activity-information-section{
        height: auto;
        padding-bottom: 20px;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .additional-information-section{
        height: auto;
        padding-bottom: 20px;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .active-interest{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .activation-section{
        height: auto;
        padding-bottom: 20px;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .radio:checked{
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2,0.85,0.32,1.2);
        --r: 43deg;
        background-color: rgb(0 0 0);
        border-color: #000;
        --o: 1;
        --s: 0.5;
    }
    .active-status{
        color: var(--Greyscale-500, #64748B);
        text-align: right;
            
        /* body/small/regular */
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
        .btn-pagination-active{
        background-color: #70D44B;
        color: #eeeeee;
    }
       .btn-pagination-active:hover{
        background-color: #a4d791;
        color: #eeeeee;
    }
    .pagination{
        padding-bottom: 10px;
    }
    ::-webkit-scrollbar {
      width: 8px; /* Width of the entire scrollbar */
    }
    ::-webkit-scrollbar-thumb {
      background-color: #70D44B; /* Color of the scrollbar handle */
      border-radius: 25px; /* Roundness of the scrollbar handle */
    }
    .add-farm-btn{
        border-radius: 8px;
        background: #124734;
        display: flex;
        width: 134px;
        height: 40px;
        padding: 8px 12px 8px 17px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: #FFF;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
        cursor: pointer;
    }
    .main-info-notes{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
        width: 50%;
    }
    .notes-txt-area{
        display: inline-flex;
        height: auto;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
        padding: 17px 23px 115px 19.5px;
        justify-content: center;
        align-items: flex-start;
        gap: 847.5px;
        flex-shrink: 0;
    }
    .notes-txt-area:focus{
        outline: none;
    }
    .notes-txt-area::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .add-actions{
    
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .add-btn{
        display: inline-flex;
        padding: 8px 37.5px 9px 37.5px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;  
        cursor: pointer;
    }
    .cancel-btn{
        display: flex;
        width: 135px;
        height: 53px;
        padding: 8px 44px 9px 41px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #EEE;
        background: #FFF;
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    
    }
    .notes-list{ 
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .note_card{     
        height: auto;  
        padding: 30px;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
        border-radius: 16px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.05);
    }
    .note_card_header{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
    }
    .note_card_title{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .note_card_data{
        color: var(--Neutral-Dark-Grey, #9C9CA4);
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
    }
    .select-mission-container{
        display: flex;
        height: 55px;
        padding: 17px 23px 17px 19.5px;
        justify-content: space-between;
        align-items: flex-start;
        
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
        width: 100%;
    }
    .select-mission-type{
        color: #333;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-color: white;
    }
}
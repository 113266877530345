.Users{
    .userCard{
        
        height: 176px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .user-name{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;        
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 0.2px;
    }
    .typeOfSpecialization{
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400; /* 19.2px */
    }
    .type-status{
        color: var(--Greyscale-500, #64748B); 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .de-active-btn{
        display: flex;
        width: 496px;
        height: 48px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        background: #124734;
        color: #FFF; 
        font-family: MadaniArabic-SemiBold;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        cursor: pointer;
    }
    .popover-bottom-left{
        background-color: white;

        left: 0;
        right: auto;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform-origin: top right;
    }
    .popover-bottom-left .popover-arrow{
        display: none;
    }
    .arrow:focus{
        outline: none;
    }
    .three-dots-li{
        display: flex;
        
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .three-dots-li-edit{
        display: flex;
        
        color: #b4b04d;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .btn-pagination-active{
        background-color: #70D44B;
        color: #eeeeee;
    }
       .btn-pagination-active:hover{
        background-color: #a4d791;
        color: #eeeeee;
    }
    .pagination{
        padding-bottom: 10px;
    }
    ::-webkit-scrollbar {
        width: 8px; /* Width of the entire scrollbar */
      }
      ::-webkit-scrollbar-thumb {
        background-color: #70D44B; /* Color of the scrollbar handle */
        border-radius: 25px; /* Roundness of the scrollbar handle */
      }

      .title{
        color: var(--Greyscale-900, #0F172A);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .suspend_btn{
        display: flex;
        width: 120px;
        height: 33px;        
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        background: #C11818;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }

    
    .mission-content{        
        height: auto;
        padding: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        background-color: white;

    }
    .mission-type{
        color: var(--Grey-01, #A7AEC1);
        text-align: right;

       
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .mission-address{
        color: var(--Black-01, #2C3659);
        text-align: right;                  
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .breeder-active{
        color: #70D44B;
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
    .breeder-disable{
        color: #EB001B;
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
    .breeder-eval{
        color: #28303F;
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .assigned-to-txt{
        color: var(--Grey-01, #A7AEC1);
        text-align: right;
        
        /* Label/L6 Plus - 12 Semi Bold - Lh 130% */
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .sorting{
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .sorting-item{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .assinged-user-name{
        color: var(--daBlack-03---Line, #292E32);
        /* Label/L6 Plus - 12 Semi Bold - Lh 130% */
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    .mission-reward{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(112, 212, 75, 0.11);
        color: #70D44B;
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .created-at{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .mission-status{
        align-items: center;
    }
    .arrow:focus{
        outline: none;
    }
    .inprogress{
        display: flex;
        width: 81px;
        height: 28.459px;
        padding: 8.757px 13.135px;
        justify-content: center;
        align-items: center;
        gap: 10.946px;
        flex-shrink: 0;
        border-radius: 54.73px;
        background: var(--Secondary-Amber-50, #FFFBEB);
        color: var(--Secondary-Amber-500-base, #F6A723);
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 10.946px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 17.514px */
    }
    .not-assigned-yet{
        display: flex;
        width: 93px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Lgrey-02, #F9F9F9);
        color: var(--Grey-01, #A7AEC1);
        text-align: right;      
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .popover-bottom-left{
        background-color: white;

        left: 0;
        right: auto;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform-origin: top right;
    }
    .popover-bottom-left .popover-arrow{
        display: none;
    }
    .three-dots-li{
        display: flex;
        justify-content: start;
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .three-dots-li-delete{
        display: flex;
        justify-content: start;
        color: #EB4335;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
        margin-bottom: 15px;
    }
    .mission-history{
        display: flex;
        width: auto;
        height: 28.459px;
        padding: 8.757px 13.135px;
        justify-content: center;
        align-items: center;
        gap: 10.946px;
        flex-shrink: 0;
        border-radius: 54.73px;
        background: #F9F9F9;
        margin-top: 10px;
        margin-left: 10px;
        color: var(--secondary-amber-500-base, #A7AEC1);
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 10.946px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .mission-adduience{
        display: flex;
        width: auto;
        height: 28.459px;
        padding: 8.757px 13.135px;
        justify-content: center;
        align-items: center;
        gap: 10.946px;
        flex-shrink: 0;
        border-radius: 54.73px;
        background: #F9F9F9;
        margin-top: 10px;
        margin-left: 10px;
        color: var(--secondary-amber-500-base, #A7AEC1);
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 10.946px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .mission-details{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .close-icon{
        // display: flex;
        // align-self: center;
    }
    .mission-range{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
    .range{
        display: inline-flex;
        padding: 13px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Lgrey-02, #F9F9F9);
        color: var(--Grey-01, #A7AEC1);
        text-align: right;
        
        /* Label/L6 Plus - 12 Semi Bold - Lh 130% */
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        overflow-y: hidden;
    }
    .mission-assign-txt{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* body/medium/semibold */
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.2px;
    }
    .assigned-at{
        color: #A7AEC1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }
    .user-name{
        color: var(--daBlack-03---Line, #292E32);
       text-align: right;
       font-family: MadaniArabic-Medium;
       font-size: 14px;
       font-style: normal;
       font-weight: 400;
       line-height: 130%; /* 18.2px */
    }
    .discription{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;

        /* body/medium/semibold */
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.2px;
    }
    .description-content{
        color: var(--Greyscale-500, #64748B);
        text-align: right;

        /* body/small/regular */
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    
   .btn-pagination-active{
    background-color: #70D44B;
    color: #eeeeee;
   }
   .btn-pagination-active:hover{
    background-color: #a4d791;
    color: #eeeeee;
   }
   .pagination{
    padding-bottom: 10px;
   }
   .step-primary.step-active .step-circle{
    background-color:#70D44B;
    border-color: #70D44B;
   }
   .step-done .step-circle, .step-done.step-primary .step-circle{
    background-color:#64748B;
    border-color: #64748B;
    color: white;
   }
   .txt{
    color: #333;
    font-family: MadaniArabic-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
    .activate_btn{
        display: flex;
        width: 120px;
        height: 33px;        
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }
    .sorting-item{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .sorting-item-active{
        background-color: #70D44B;
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%; /* 28px */
    }
    .breeder-active{
        color: #70D44B;
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
    .breeder-disable{
        color: #EB001B;
        text-align: center;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }
}
.EditHousingCycle{
     
    .add-section{ 
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .add-reward-section{
        height: auto;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
        padding-bottom: 20px;
    }
    .mission-details{
        width: 50%;
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
    }
    .wrap{
        text-wrap: nowrap;
    }
    .no-wrap{
        text-wrap: balance;
    }
    .mission-type{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .select-mission-type{
        color: #333;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .select-mission-type:focus{
        outline: none;
    }
    .select-mission-container{
        display: inline-flex;
        height: 55px;
        padding: 17px 23px 17px 19.5px;
        justify-content: center;
        align-items: flex-start;
        gap: 881.5px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .select-dajin-container{
        display: inline-flex;
        height: 55px;
        padding: 17px 23px 17px 19.5px;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .map-container{
        border-radius: 20px;
        padding: 10px;
    }
    .map{
        border-radius: 20px;
        width: 100%;

    }
    .mission-map-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding: 17px 23px 17px 385px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0; 
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .mission-map-input:focus{
        outline: none;
    }
    .mission-map-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .mission-address-input{
        display: flex;
        width: 100%;
        height: 55px;
        padding-right: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .mission-address-input:focus{
        outline: none;
    }
    .mission-address-input::placeholder{
        color: #9BA0B1;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .mission-text-area{
        display: flex;            
        padding: 22px 23px 60px 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #E2E2E2;
        background: #FFF;
    }
    .mission-text-area:focus{
        outline: none;
    }
    .bonus-text{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .add-actions{
        width: 100%;
        height: 88px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .add-btn{
        display: inline-flex;
        padding: 8px 37.5px 9px 37.5px;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background: #124734;
        color: #FFF;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;  
        cursor: pointer;
    }
    .cancel-btn{
        display: flex;
        width: 135px;
        height: 53px;
        padding: 8px 44px 9px 41px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 15px;
        border: 1px solid #EEE;
        background: #FFF;
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;

    }
    .react-datepicker-wrapper {
        width: 100%;
      }
    
      .react-datepicker__input-container {
        width: 100%;
    
        input {
          width: 100%;
          display: flex;       
          height: 48px;
          padding: 17px 23px 17px 26px;
          justify-content: center;
          align-items: flex-start;        
          flex-shrink: 0;
          border-radius: 15px;
          border: 1px solid #E2E2E2;
          background: #FFF;
          background-image: url('../../../../Assets/Icons/Calender.jpeg');


          background-size: 4%;  
          background-repeat: no-repeat;
          background-position: 20px center; 
         //  padding-left: 5px;
        }
      }
    
      .react-datepicker {
        font-size: 16px;
        background-color: #f0f0f0;
        border-radius: 8px;
        overflow: hidden;
    
        .react-datepicker__header {
          background-color: #70D44B;
          color: #fff;
        }
    
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          color: #39393a;
        }
    
        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
          background-color: #007bff;
          color: #fff;
        }
      }
    // Media query for small screens
@media (max-width: 600px) {
   
      .react-datepicker__input-container {
         width: 100%;
     
         input {
           width: 110%;
           display: flex;       
           height: 48px;
           font-size: 13px;
           padding: 17px 10px 17px 0px;
           justify-content: center;
           align-items: flex-start;        
           flex-shrink: 0;
           border-radius: 15px;
           border: 1px solid #E2E2E2;
           background: #FFF;
           background-image: url('../../../../Assets/Icons/Calender.jpeg');
           background-size: 14%;  
           background-repeat: no-repeat;
           background-position: 20px center; 
           padding-left: 5px;
         }
       }
   
 }
}
.MovementRecord{
    .history-section{
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .farm-info-title{
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #F9F9F9;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
   }
   .title{
    color: #333;
    text-align: right;
    font-family: MadaniArabic-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   }
   .mission-address-input{
    display: flex;
    width: 490px;
    height: 55px;
    padding-right: 10px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #E2E2E2;
    background: #FFF;
   }
   .mission-address-input:focus{
    outline: none;
    }
    .mission-address-input::placeholder{
    color: #9BA0B1;
    text-align: right;
    font-family: MadaniArabic-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
   .select-mission-type{
    color: #333;
    text-align: right;
    font-family: MadaniArabic-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: white;
    }
    .select-mission-container{
    display: flex;
    height: 55px;
    padding: 17px 23px 17px 19.5px;
    justify-content: space-between;
    align-items: flex-start;
    
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #E2E2E2;
    background: #FFF;
    width: 100%;
    }
    .content{
        width: 1040px;
        height: auto;
        padding-bottom: 20px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .status-green{
        color: #70D44B;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
    }
    .status-orange{
        color: #FF6D00;
        text-align: right;

        /* Headings/H5 - 16 Bold - Lh 130% */
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
    }
    .status-red{
        color: #830909 ;
        text-align: right;

        /* Headings/H5 - 16 Bold - Lh 130% */
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        
    }
    .status-content{
        color: var(--Black-01, #2C3659); 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    .created-at{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
}
.Courses{
    .title{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .courses-section{
        display: flex;
        width: 352px;
        padding: 30px;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
        border-radius: 16px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.05);
    }
    .status{        
        display: inline-flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--Neutral-White, #FFF);
        backdrop-filter: blur(15px);
        color: #2C3659;
        text-align: center;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;  
    }
    .course-img{
        border-radius: 20px;
    }
    .course-title{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        align-self: stretch;
    }
    .user-name{
        color: var(--Neutral-Dark-Grey, #9C9CA4);
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;  
    }
    .number{
        color: #2C3659;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;  
    }
    .score{
        color: #000;
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .percentage{
        color: #124734;
        font-family: MadaniArabic-Regular;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
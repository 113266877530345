.ListContent{
    .head{
        color: #333;
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style-type: circle;
      }
}
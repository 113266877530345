.BreederHeader{
    background-color: #FFF;
    .main-element-active{
        border-radius: 8px;
        background: #F1FAEE;
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: #70D44B;
        text-align: right;
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        cursor: pointer;
     }
     .main-element{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
        cursor: pointer;

     }
     .filter-section{
        display: flex;
        width: 99px;
        height: 40px;
        padding: 8px 12px 8px 17px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: var(--Greyscale-500, #64748B);
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
        cursor: pointer;
     }
     .add-farm-btn{
      border-radius: 8px;
      background: #124734;
      display: flex;
      width: 134px;
      height: 40px;
      padding: 8px 12px 8px 17px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      color: #FFF;
      text-align: right; 
      font-family: MadaniArabic-Medium;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;  
      letter-spacing: 0.2px;
      cursor: pointer;
   }
     .popover-bottom-left{
      background-color: white;

      left: 0;
      right: auto;
      transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
      transform-origin: top right;
   }
   .search-input{
      padding-right: 40px;
   }
   .search-input:focus{
      outline: none;
   }
   .search-input::placeholder{
      color: var(--Greyscale-500, #64748B);
      text-align: right;
      font-family: MadaniArabic-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
   }
   .breeder_name{
      color: var(--color-text-black, #1E1E1E);
      font-family: MadaniArabic-Light;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
   }
   .submit-filter{
      display: flex;
      padding: 8px 12px 8px 17px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      background: #124734;
      color: #FFF;
      text-align: right; 
      font-family: MadaniArabic-Medium;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; 
      letter-spacing: 0.2px;
   }
}
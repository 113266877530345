.CreditBalanceOnly{
    background: #F1F5F9;    
    .userCard{
        
        height: 176px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
    }
    .user-name{
        color: var(--Greyscale-900, #0F172A);
        text-align: right;        
        font-family: MadaniArabic-SemiBold;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 0.2px;
    }
    .typeOfSpecialization{
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400; /* 19.2px */
    }
    .type-status{
        color: var(--Greyscale-500, #64748B); 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .de-active-btn{
        display: flex;
        width: 496px;
        height: 48px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 15px;
        background: #124734;
        color: #FFF; 
        font-family: MadaniArabic-SemiBold;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        cursor: pointer;
    }
    .popover-bottom-left{
        background-color: white;

        left: 0;
        right: auto;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform-origin: top right;
    }
    .popover-bottom-left .popover-arrow{
        display: none;
    }
    .arrow:focus{
        outline: none;
    }
    .three-dots-li{
        display: flex;
        
        color: #64748B;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .three-dots-li-edit{
        display: flex;
        
        color: #b4b04d;
        text-align: right;
        font-family: MadaniArabic-Regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 15px;
    }
    .credit{
        color: #28303F;
        text-align: right;
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
}
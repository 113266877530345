.Exams{
    .title{
        color: var(--Black-01, #2C3659);
        text-align: right;  
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
        margin-bottom: 20px;
    }
    .exam-container{     
        padding: 20px;   
        height: auto;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--White-General, #FFF);
        box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
        margin-bottom: 20px;
    }
    .status{
        display: inline-flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: rgba(235, 0, 27, 0.11);
        color: #EB001B;
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
        height: 40px;
    }
    .course-name{
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
    }
    .exam-address{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
        margin-bottom: 10px;
    }
    .finished-at{
        display: flex;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: var(--Grey-01, #A7AEC1);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }
    .quesion-title{
        color: var(--Black-01, #2C3659);
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;  
    }
    .selected-answer{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #F1FAEE;
        color: #70D44B;
        text-align: right; 
        font-family: MadaniArabic-SemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
    }
    .answer{
        display: inline-flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: var(--Greyscale-500, #64748B);
        text-align: right; 
        font-family: MadaniArabic-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;  
        letter-spacing: 0.2px;
    }
}